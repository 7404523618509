import React, { useEffect, useMemo, useRef, useState } from "react";
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { useMyGlobal } from "../component/Global";
import axios from "axios";
import { Button, Dropdown, Form, Header, Icon, Label, Popup } from "semantic-ui-react";
import Modal from "rsuite/Modal"
import Stack from "react-bootstrap/Stack"
import DateRangePicker from 'rsuite/DateRangePicker';
import Badge from "rsuite/Badge"
import { badgeColor, copyTextToClipboard, curency, curencyNoSym, currentDate } from "../component/utils";
import moment from "moment";
import { Reacttable } from "../component/Reacttable";
import { Toaster } from "../component/Toaster";
import ReactDOMServer from 'react-dom/server';

export const PaymentInvoice = () => {
    const { host, userProfile, supplierOpt } = useMyGlobal();
    const [modalAddInv, setModalAddInv] = useState(false)
    const [modalGr, setModalGr] = useState(false)
    const [dataGr, setDataGr] = useState([])
    const [loader, setLoader] = useState(false)
    const [modalGrDetail, setModalGrDetail] = useState(false)
    const [selectedDataGr, setSelectedDataGr] = useState(null)
    const [totalCogs, setTotalCogs] = useState(0)
    const [selectDo, setSelectDo] = useState('')
    const [poType, setPoType] = useState('')
    const [invDate, setInvDate] = useState(new Date().toISOString().split('T')[0])
    const [top, setTop] = useState(30)
    const [ppn, setPpn] = useState('11')
    const [totalPpn, setTotalPpn] = useState(0)
    const [totalInv, setTotalInv] = useState(0)
    const [totalDpp, setTotalDpp] = useState(0)
    const [totalGr, setTotalGr] = useState(0)
    const tableRef = useRef()
    const [invDetail, setInvDetail] = useState([])
    const [invType, setInvType] = useState('pembelian')
    const [vendor, setVendor] = useState('')
    const [disableInput, setDisabelInput] = useState(true)
    const [selectDoNumber, setSelectDoNumber] = useState([])
    const [invData, setInvData] = useState([])
    const [modalDetailInv, setModalDetailInv] = useState(false)
    const [selectedInvDetail, setSelectInvDetail] = useState(null)
    const [modalChangeCogs, setModalChangeCogs] = useState(false)
    const [newCogs, setNewCogs] = useState(null)

    const [dateRange, setDateRange] = useState([
        new Date(moment().startOf('year')),
        new Date(moment()),
    ])

    const invTypeOpt = [
        { key: 1, text: 'Pembelian', value: 'pembelian' },
        { key: 2, text: 'Retur Pembelian', value: 'retur pembelian' },
    ]

    const payOption = [
        { key: 1, text: '10 Days', value: 10 },
        { key: 2, text: '15 Days', value: 15 },
        { key: 3, text: '30 Days', value: 30 },
        { key: 4, text: '45 Days', value: 45 },
        { key: 5, text: '60 Days', value: 60 },
    ]

    const columnsDataGR = useMemo(() => [
        {
            Header: "DO Number",
            accessor: "header.do_number"
        },
        {
            Header: "Supplier",
            accessor: "header.vendor_name",
            Cell: ({ cell }) => (
                <div style={{ width: 100 }}>
                    <Popup content={cell.value} size="tiny" trigger={
                        <div className="text-truncate">{cell.value}</div>
                    } />
                </div>
            )
        },
        {
            Header: "Warehouse",
            accessor: "header.warehouse_desc",
        },
        {
            Header: "GR Date",
            accessor: "header.gr_date"
        },
        {
            Header: "PO Type",
            accessor: "header.po_type"
        },
        {
            Header: "Ord",
            accessor: "header.total_ordered"
        },
        {
            Header: "Rec",
            accessor: "header.total_receipt"
        },
        {
            Header: "Status",
            accessor: "header.status",
            Cell: ({ cell }) => (
                <>
                    <span className="me-2"><Badge style={{ background: badgeColor(cell.value) }} /></span>
                    <span className="text-capitalize">{cell.value}</span>
                </>
            )
        },
        {
            Header: "User",
            accessor: "header.user_name",
            Cell: ({ cell }) => (
                <Stack direction="vertical">
                    <div>{cell.value}</div>
                    <div className="text-muted" style={{ lineHeight: '0.8em' }}>{cell.row.original.header.role_name}</div>
                </Stack>
            )
        },
        {
            Header: "Action",
            accessor: null,
            Cell: ({ cell }) => {
                return (
                    <Button.Group size="mini" basic>
                        <Popup content="Detail" trigger={
                            <Button icon={{ name: 'list layout', color: 'blue' }} onClick={() => handleClickModalGrDetail(cell.row.original)} />
                        } />
                    </Button.Group>
                )
            }
        },
    ])

    const columnsGrItem = useMemo(() => [
        {
            Header: "Article",
            accessor: "article"
        },
        {
            Header: "Brand",
            accessor: "brand_code"
        },
        {
            Header: "Size",
            accessor: "size"
        },
        {
            Header: "Description",
            accessor: "description",
            Cell: ({ cell }) => (<div style={{ width: '200px' }} className="text-truncate">{cell.value}</div>)
        },
        {
            Header: "Cogs",
            accessor: "cogs",
            Cell: ({ cell }) => (curencyNoSym.format(cell.value))
        },
        {
            Header: "Price",
            accessor: "price",
            Cell: ({ cell }) => (curencyNoSym.format(cell.value))
        },
        {
            Header: "Qty",
            accessor: "qty_receipt"
        },
        {
            Header: "Total Cogs",
            accessor: 'total_cogs',
            Cell: ({ cell }) => (
                curencyNoSym.format(cell.row.original.qty_receipt * cell.row.original.cogs)
            )
        },
        {
            Header: "Status",
            accessor: "item_state",
            Cell: ({ cell }) => (
                <>
                    <span className="me-2"><Badge style={{ background: badgeColor(cell.value) }} /></span>
                    <span className="text-capitalize">{cell.value}</span>
                </>
            )
        }
    ])

    const columnsInvDet = useMemo(() => [
        {
            Header: 'Brand',
            accessor: 'brand_code'
        },
        {
            Header: "DO Number",
            accessor: "do_number"
        },
        {
            Header: 'Article',
            accessor: 'article',
            Cell: ({ cell }) => (
                <div>
                    <Popup content="Edit Cogs" size="tiny" trigger={
                        <span><Icon name="edit" color="blue" style={{ cursor: 'pointer' }} onClick={() => handleClickChangeCogs(cell.row.original)} /></span>
                    } />
                    <span>{cell.value}</span>
                </div>
            )
        },
        {
            Header: 'Description',
            accessor: 'description',
            Cell: ({ cell }) => (
                <div style={{ width: '120px' }}>
                    <Popup content={cell.value} size="tiny" trigger={
                        <div className="text-truncate">{cell.value}</div>
                    } />
                </div>
            )
        },
        {
            Header: 'Size',
            accessor: 'size'
        },
        {
            Header: 'Barcode',
            accessor: 'barcode'
        },
        {
            Header: 'Cogs',
            accessor: 'cogs',
            Cell: ({ cell }) => (
                <div>
                    <span className="me-2">{curencyNoSym.format(cell.value)}</span>
                    {
                        cell.row.original.cogs_edited === 'Y' ? <span><Icon name="check circle" color="blue" /></span> : <></>
                    }
                </div>
            )
        },
        {
            Header: 'Price',
            accessor: 'price',
            Cell: ({ cell }) => curencyNoSym.format(cell.value)
        },
        {
            Header: 'Qty Ord',
            accessor: 'qty'
        },
        {
            Header: 'Receipt',
            accessor: 'qty_receipt'
        },
        {
            Header: 'Total Cogs',
            accessor: 'total',
            Cell: ({ cell }) => curencyNoSym.format(cell.value)
        },
    ])

    const columnsInv = useMemo(() => [
        {
            Header: 'Inv. Number',
            accessor: 'header.inv_number'
        },
        {
            Header: 'Date',
            accessor: 'header.inv_date'
        },
        {
            Header: 'Due',
            accessor: 'header.due_date'
        },
        {
            Header: 'Type',
            accessor: 'header.po_type'
        },
        {
            Header: 'Supplier',
            accessor: 'header.vendor_name',
            Cell: ({ cell }) => (
                <div style={{ width: 180 }}>
                    <div className="text-truncate">{cell.value}</div>
                </div>
            )
        },
        {
            Header: 'PPN',
            accessor: 'header.ppn'
        },
        {
            Header: 'Total PPN',
            accessor: 'header.total_ppn',
            Cell: ({ cell }) => (
                <div>{curencyNoSym.format(cell.value)}</div>
            )
        },
        {
            Header: 'Total DPP',
            accessor: 'header.total_dpp',
            Cell: ({ cell }) => (
                <div>{curencyNoSym.format(cell.value)}</div>
            )
        },
        {
            Header: 'Total',
            accessor: 'header.total_invoice',
            Cell: ({ cell }) => (
                <div>{curencyNoSym.format(cell.value)}</div>
            )
        },
        {
            Header: 'TOP',
            accessor: 'header.top',
            Cell: ({ cell }) => (`${cell.value} Days`)
        },
        {
            Header: 'Qty',
            accessor: 'header.qty_receipt'
        },
        {
            Header: 'Status',
            accessor: 'header.status',
            Cell: ({ cell }) => (
                <Stack direction="horizontal" gap={2}>
                    <Badge color={cell.value === 'paid' ? 'green' : 'red'} />
                    <div className="text-capitalize">{cell.value}</div>
                </Stack>
            )
        },
        {
            Header: 'User',
            accessor: 'header.user_name',
            Cell: ({ cell }) => (
                <Stack direction="vertical" gap={1}>
                    <div style={{ lineHeight: '0.5em' }}>{cell.value}</div>
                    <div className="text-muted" style={{ lineHeight: '0.9em' }}>{cell.row.original.header.role_name}</div>
                </Stack>
            )
        },
        {
            Header: 'Action',
            accessor: '',
            Cell: ({ cell }) => (
                <Button.Group basic size="mini">
                    <Popup content="Detail" size="tiny" trigger={
                        <Button icon={{ name: 'list layout', color: 'blue' }} onClick={() => handleClickInvDet(cell.row.original)} />
                    } />
                </Button.Group>
            )
        },
    ])

    const handleClickChangeCogs = (data) => {
        setNewCogs(data)
        setModalChangeCogs(true)
    }

    const columnsInvDetData = useMemo(() => [
        {
            Header: 'Brand',
            accessor: 'brand_code'
        },
        {
            Header: 'Do Number',
            accessor: 'do_number'
        },
        {
            Header: 'Article',
            accessor: 'article'
        },
        {
            Header: 'Description',
            accessor: 'description',
            Cell: ({ cell }) => (
                <div style={{ width: '150px' }}>
                    <div className="text-truncate">{cell.value}</div>
                </div>
            )
        },
        {
            Header: 'Size',
            accessor: 'size'
        },
        {
            Header: 'Barcode',
            accessor: 'barcode'
        },
        {
            Header: 'Qty',
            accessor: 'qty_receipt'
        },
        {
            Header: 'Cogs',
            accessor: 'cogs',
            Cell: ({ cell }) => (
                <div>{curencyNoSym.format(cell.value)}</div>
            )
        },
        {
            Header: 'Price',
            accessor: 'price',
            Cell: ({ cell }) => (
                <div>{curencyNoSym.format(cell.value)}</div>
            )
        },
        {
            Header: 'Total Cogs',
            accessor: 'total',
            Cell: ({ cell }) => (
                <div>{curencyNoSym.format(cell.value)}</div>
            )
        },
    ])

    const handleClickInvDet = (data) => {
        setSelectInvDetail(data)
        setModalDetailInv(true)
    }

    const getInvoiceData = () => {
        const formatDate = (date) => moment(date).format('YYYY-MM-DD');
        const [startDate, endDate] = dateRange.map(date => formatDate(date));
        const date_range = [startDate, endDate];

        axios({
            method: "GET",
            url: `${host}/payment/index.php?action=getInvoice`,
            headers: {
                "Content-Type": "application/json",
            },
            params: {
                range: date_range
            }
        }).then(res => {
            if (res.data.status === 200) {
                setInvData(res.data.data)
            }
        })
    }

    const handleGetSelected = () => {
        if (tableRef.current) {
            let newDoNumbers = [...selectDoNumber];
            const selected = tableRef.current.getSelectedRows()
            const allDetails = selected.flatMap(item => {
                const { do_number } = item.header;
                // Periksa apakah do_number sudah ada di newDoNumbers
                if (!newDoNumbers.includes(do_number)) {
                    // Jika belum ada, tambahkan ke newDoNumbers
                    newDoNumbers.push(do_number);
                } else {
                    // Jika sudah ada, bisa tambahkan logika untuk menampilkan alert
                    alert(`Nomor DO ${do_number} sudah disertakan sebelumnya.`);
                }
                return item.detail.map(detailItem => ({
                    ...detailItem,
                    do_number: item.header.do_number,
                    cogs_edited: 'N'
                }))

            })

            setSelectDoNumber(newDoNumbers);
            setInvDetail(prev => [...prev, ...allDetails])
            setModalGr(false)
        }
    }

    const handleClickModalGrDetail = (data) => {
        setSelectedDataGr(dataGr?.find(i => i.header.gr_number === data.header.gr_number))
        setModalGrDetail(true)
    }

    const handleClickCreateInv = () => {
        setModalAddInv(true)
    }

    const handleClickBrowse = () => {
        if (invType !== '' && vendor !== '') {
            getDataGoodReceipt()
            setModalGr(true)
        } else {
            Toaster.fire({
                icon: 'warning',
                text: 'Invoice Type & Vendor cannot be empty',
                position: 'top-right'
            })
        }
    }

    const getDataGoodReceipt = async () => {
        const formatDate = (date) => moment(date).format('YYYY-MM-DD');
        const [startDate, endDate] = dateRange.map(date => formatDate(date));
        const date_range = [startDate, endDate];
        setLoader(true)
        await axios({
            method: "GET",
            url: `${host}/purchasing/index.php?action=gr`,
            headers: {
                "Content-Type": "application/json",
            },
            params: {
                range: date_range
            }
        }).then(res => {
            if (res.data.status === 200) {
                const data = res.data.data
                if (data.length > 0) {
                    // Ambil semua do_number dari invData
                    const poNumbers = invData.flatMap(inv => inv.detail.map(detail => detail.po_number))
                    // Unifikasi dibuat unik
                    const unikPo = [...new Set(poNumbers)]
                    // Filter data untuk hanya menyertakan item yang tidak memiliki do_number yang ada di invData
                    const filteredData = data.filter(item =>
                        !item.detail.some(detail => unikPo.includes(detail.po_number))
                    )
                    // Log hasil filteredData
                    setDataGr(filteredData.filter(i => i.header.po_type.toLowerCase() == invType && i.header.id_vendor == vendor))
                }
            }
        }).finally(setLoader(false))
    }

    const handleDeleteDo = (doNumber) => {
        const filterSelDo = selectDoNumber.filter(i => i !== doNumber)
        const filterDetail = invDetail.filter(i => i.do_number !== doNumber)
        const newTotalGr = filterDetail.reduce((total, cur) => total + parseFloat(cur.total), 0)
        setTotalInv(newTotalGr)
        setSelectDoNumber(filterSelDo)
        setInvDetail(filterDetail)
    }

    const clearForm = () => {
        setInvType('')
        setInvDate(new Date().toISOString().split('T')[0])
        setVendor('')
        setPpn(11)
        setTotalInv(0)
        setTotalPpn(0)
        setTotalDpp(0)
        setTop(30)
        setInvDetail([])
    }

    const handleSubmit = () => {
        const cfm = window.confirm('Are you sure ?')
        if (cfm) {
            if (invDetail) {
                const header = {
                    inv_type: invType,
                    inv_date: invDate,
                    id_supplier: vendor,
                    id_user: userProfile.profile.id_user,
                    ppn: ppn,
                    total_inv: totalInv,
                    total_ppn: totalPpn,
                    total_dpp: totalDpp,
                    top: top,
                    qty_order: invDetail.reduce((total, cur) => total + parseInt(cur.qty), 0),
                    qty_receipt: invDetail.reduce((total, cur) => total + parseInt(cur.qty_receipt), 0),
                }

                const detail = invDetail

                axios({
                    method: "POST",
                    url: `${host}/payment/index.php?action=createInvoiceAp`,
                    headers: {
                        "Content-Type": "application/json",
                    },
                    data: {
                        header: header,
                        detail: detail,
                    }
                }).then(res => {
                    if (res.data.status === 200) {
                        Toaster.fire({
                            icon: 'success',
                            title: 'Success create Invoice',
                            html: ReactDOMServer.renderToString(
                                <div>
                                    <span>Invoice number : </span>
                                    <span className="text-primary">
                                        {res.data.inv_number} 
                                    </span>
                                    <span className="ms-3"><i id="copy-icon" className="ui icon copy outline" style={{ cursor: 'pointer' }}></i></span>
                                </div>
                            ),
                            position: 'center',
                            timer:false,
                            toast:false,
                            showCloseButton: false, 
                            showConfirmButton: true ,
                            didOpen: () => {
                                const copyIcon = document.getElementById('copy-icon');
                                if (copyIcon) {
                                    copyIcon.addEventListener('click', () => copyTextToClipboard(res.data.inv_number));
                                }
                            }

                        })
                        getInvoiceData()
                        setModalAddInv(false)
                        clearForm()
                    } else {
                        Toaster.fire({
                            icon: 'error',
                            text: res.data.msg,
                            position: 'top-right'
                        })
                    }
                })
            } else {
                Toaster.fire({
                    icon: 'warning',
                    text: 'You have not selected the DO Number',
                    position: 'top-right'
                })
            }
        }
    }

    const handleSubmitEditCogs = () => {
        if (newCogs) {
            setInvDetail(prevDetails =>
                prevDetails.map(item => {
                    // Cek apakah item memenuhi kondisi
                    if (item.article == newCogs.article && item.do_number == newCogs.do_number) {
                        return {
                            ...item,    // Salin properti lainnya
                            cogs: newCogs.cogs,   // Ubah cogs 
                            total: parseFloat(newCogs.cogs) * parseInt(item.qty_receipt),
                            cogs_edited: 'Y'
                        };
                    }
                    return item; // Jika tidak memenuhi kondisi, kembalikan item tanpa perubahan
                })
            )
            setModalChangeCogs(false)
        } else {
            alert("Article not selected yet..\n Please select one article first")
        }
    }
    console.log(invDetail)
    useEffect(() => {
        const detailTotal = selectedDataGr?.detail.reduce((detailAcc, item) => {
            const qtyReceipt = parseInt(item.qty_receipt, 10);
            const cogs = parseInt(item.cogs, 10);

            if (!isNaN(qtyReceipt) && !isNaN(cogs)) {
                return detailAcc + (qtyReceipt * cogs);
            }

            return detailAcc;
        }, 0);
        setTotalCogs(detailTotal)
    }, [selectedDataGr, invDetail])

    useEffect(() => {
        const pajak = parseInt(`1${ppn}`)
        const total_gr = invDetail.reduce((total, cur) => total + parseFloat(cur.total), 0)
        const total_dpp = (100 / pajak) * total_gr
        // const total_dpp = total_gr
        const total_ppn = total_dpp * (ppn / 100)
        const total_inv = total_dpp + total_ppn

        setTotalInv(parseFloat(total_inv.toFixed(2)))
        setTotalPpn(parseFloat(total_ppn.toFixed(2)))
        setTotalDpp(parseFloat(total_dpp.toFixed(2)))

    }, [ppn, invDetail])

    useEffect(() => {
        if (invType === '' || vendor === '') {
            setDisabelInput(true)
            setInvDetail([])
            setSelectDoNumber([])
        }
    }, [invType, vendor])

    useEffect(() => {
        if (invDetail.length > 0) {
            setDisabelInput(false)
        }
    }, [invDetail])

    useEffect(() => {
        getInvoiceData()
    }, [])

    return (
        <>
            <Container>
                <div className="mb-3 mt-2">
                    <div className="h4">Payable Invoice</div>
                </div>
                <Row className="d-flex justify-content-center">
                    <Col xl={12} lg={12} md={12} className="mb-3">
                        <Button content="Create New Invoice" icon="add" onClick={handleClickCreateInv} color="blue" />
                    </Col>
                    <Col xl={12} lg={12} md={12}>
                        <Reacttable
                            data={invData}
                            columns={columnsInv}
                            noData={invData.length === 0 ? true : false}
                            pageRow={8}
                        />
                    </Col>
                </Row>
            </Container>

            <Modal open={modalAddInv} onClose={() => setModalAddInv(false)} overflow={false} backdrop="static" size="lg">
                <Modal.Header><Modal.Title>Create Payable Invoice</Modal.Title></Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xl={12} lg={12} md={12}>
                            <Form id="frmInv" onSubmit={handleSubmit}>
                                <Form.Group>
                                    <Form.Field>
                                        <label>Invoice Type</label>
                                        <Dropdown
                                            placeholder="Ex : Pembelian"
                                            fluid
                                            selection
                                            options={invTypeOpt}
                                            value={invType}
                                            clearable
                                            onChange={(e, { value }) => setInvType(value)}
                                            style={{ width: '150px' }}
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Supplier / Vendor</label>
                                        <Dropdown
                                            placeholder="Ex : PT. ADIDAS INDONESIA"
                                            fluid
                                            selection
                                            options={supplierOpt}
                                            clearable
                                            value={vendor}
                                            onChange={(e, { value }) => setVendor(value)}
                                            style={{ width: '320px' }}
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>.</label>
                                        <Button type="button" icon="ellipsis horizontal" onClick={handleClickBrowse} color="blue" />
                                    </Form.Field>
                                    {/* <Form.Input readOnly type="text" width={4} label="DO Number" value={selectDo} required action={{ icon: 'ellipsis horizontal', color: 'blue', onClick: handleClickBrowse, type: 'button' }} placeholder='Browse DO List' /> */}
                                    {/* <Form.Input readOnly type="text" label="PO Type" value={poType} required placeholder="Autofill" /> */}
                                    <Form.Field>
                                        <Form.Input disabled={disableInput} type="date" label="Invoice Date" value={invDate} onChange={(e) => setInvDate(e.target.value)} required />
                                    </Form.Field>
                                    <Form.Field>
                                        <label >Term of Payment *</label>
                                        <Dropdown
                                            placeholder="30 Days"
                                            fluid
                                            disabled={disableInput}
                                            options={payOption}
                                            selection
                                            clearable
                                            search
                                            value={top}
                                            onChange={(e, { value }) => setTop(value)}
                                            style={{ width: '120px' }}

                                        />
                                    </Form.Field>
                                    <Form.Input disabled={disableInput} label="PPN" width={2} value={ppn} onChange={(e) => setPpn(e.target.value)} required />
                                </Form.Group>
                            </Form>
                        </Col>
                        <Col xl={12} lg={12} md={12} className="mb-3">
                            <small className="fw-bold">You have selected this DO number : </small>
                            <div style={{ overflowX: 'auto' }}>
                                {
                                    selectDoNumber.length > 0 ?
                                        selectDoNumber.map((item, i) =>
                                            <Label key={i} className="mb-2" circular basic color="orange">
                                                {item}
                                                <Icon name="delete" onClick={() => handleDeleteDo(item)} />
                                            </Label>
                                        )
                                        : <small style={{ color: 'coral' }}>No DO Number was selected ...</small>
                                }
                            </div>
                        </Col>
                        <Col xl={12} lg={12} md={12}>
                            <Reacttable
                                data={invDetail}
                                columns={columnsInvDet}
                                pageRow={5}
                                totals={['qty', 'qty_receipt', 'total']}
                                noData={invDetail.length > 0 ? false : true}
                            />
                        </Col>
                        <Col xl={12} lg={12} md={12}>
                            <Label content={'Total PPN : ' + curencyNoSym.format(totalPpn)} color="blue" basic circular />
                            <Label content={'Total DPP : ' + curencyNoSym.format(totalDpp)} color="blue" basic circular />
                            <Label content={'Total Invoice : ' + curencyNoSym.format(totalInv)} color="orange" basic circular />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button content="Create Invoice" icon="plus" color="blue" type="submit" form='frmInv' disabled={disableInput} />
                </Modal.Footer>
            </Modal>

            <Modal open={modalGr} onClose={() => setModalGr(false)} overflow={false} backdrop='static' size='lg'>
                <Modal.Header>
                    <Modal.Title>Good Receipt by DO Number </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Reacttable
                        data={dataGr}
                        columns={columnsDataGR}
                        pageRow={8}
                        totals={['header.total_ordered', 'header.total_receipt']}
                        noData={dataGr.length === 0 ? true : false}
                        checkbox
                        ref={tableRef}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button content="Submit" icon="plus" type="button" onClick={handleGetSelected} color="blue" />
                </Modal.Footer>
            </Modal>

            <Modal open={modalGrDetail} onClose={() => setModalGrDetail(false)} overflow={false} size="md">
                <Modal.Header>
                    <Modal.Title>Good Receipt Detail</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col lg={6} className="mb-3" style={{ fontSize: '0.9em' }} >
                            <div className="fw-bold mb-3">{selectedDataGr?.header.po_type}</div>
                            <Stack direction="horizontal">
                                <Stack direction="vertical">
                                    <div>PO Number</div>
                                    <div>DO Number</div>
                                    <div>Warehouse</div>
                                    <div>Grand Total</div>
                                </Stack>
                                <Stack direction="vertical">
                                    <div>: {selectedDataGr?.header.po_number}</div>
                                    <div>: {selectedDataGr?.header.do_number}</div>
                                    <div>: {selectedDataGr?.header.warehouse_desc}</div>
                                    <div>: {curency.format(totalCogs)}</div>
                                </Stack>
                            </Stack>
                        </Col>
                        <Col lg={12}>
                            <Reacttable
                                data={selectedDataGr?.detail}
                                columns={columnsGrItem}
                                pageRow={6}
                                totals={['cogs', 'price', 'qty_receipt']}
                                noData={selectedDataGr?.detail.length === 0 ? true : false}
                            />
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

            <Modal open={modalDetailInv} onClose={() => setModalDetailInv(false)} overflow={false} backdrop="static" size='lg'>
                <Modal.Header>
                    <Modal.Title>Invoice Detail</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xl={12} lg={12}>
                            <Reacttable
                                data={selectedInvDetail?.detail}
                                columns={columnsInvDetData}
                                noData={selectedInvDetail?.detail.length === 0 ? true : false}
                                pageRow={10}
                                totals={['total', 'qty_receipt']}
                            />
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

            <Modal open={modalChangeCogs} onClose={() => setModalChangeCogs(false)} backdrop="static" overflow={false} size="xs">
                <Modal.Header>
                    <Modal.Title>Change Cogs</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-2">Cogs for Article : <span className="fw-bold">{newCogs?.article}</span></div>
                    <Form id="frmChangeCogs" onSubmit={handleSubmitEditCogs}>
                        <Form.Input type="number" label="New Cogs" value={newCogs?.cogs} step="0.01" onChange={(e) => setNewCogs({ ...newCogs, cogs: e.target.value })} />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button content="Update" icon="edit" form="frmChangeCogs" type="submit" color="blue" />
                </Modal.Footer>
            </Modal>
        </>
    )
}