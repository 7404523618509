import React, { useEffect, useMemo, useState } from "react";
import { Reacttable } from "../component/Reacttable";
import axios from "axios";
import { Col, Container, Row } from "react-bootstrap";
import { useMyGlobal } from "../component/Global";
import { Button, Form, FormInput, Icon, Input, Label, Popup } from "semantic-ui-react";
import { Toaster } from "../component/Toaster";
import { Modal } from "rsuite";

export const MasterArticle = () => {
    const [dataArticle, setDataArticle] = useState([])
    const { IDR, host, userProfile } = useMyGlobal()
    const [excelFile, setExcelFile] = useState(null)
    const [loadingImpt, setLoadingImpt] = useState(false)
    const [totalItem, setTotalItem] = useState(0)
    const [totalArt, setTotalArt] = useState(0)
    const [totalBrand, setTotalBrand] = useState(0)
    const urlTemplate = `${host}/import_template/master_article.xlsx`
    const [noData, setNoData] = useState(false)
    const [modalEditBrcd, setModalEditBrcd] = useState(false)
    const [modalEditSeason, setModalEditSeason] = useState(false)
    const [modalEditSize, setModalEditSize] = useState(false)
    const [selectedRow, setSelectedRow] = useState(null)
    const [modalCheck, setModalCheck] = useState(false)
    const [excelData, setExcelData] = useState([])
    const [columnsCheck, setColumnCheck] = useState([])

    const getData = async () => {
        await axios({
            method: 'GET',
            url: `${host}/product/index.php?action=productMaster`,
            headers: {
                "Content-Type": "application/json",
                // "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107",
            }
        })
            .then(res => {
                if (res.data.data.length === 0) {
                    setNoData(true)
                }
                if (res.data.status === 200) {
                    const dataLength = res.data.data.length
                    const artLength = new Set(res.data.data.map(product => product.article)).size
                    const brandLength = new Set(res.data.data.map(product => product.brand)).size
                    setDataArticle(res.data.data)
                    setTotalItem(dataLength)
                    setTotalArt(artLength)
                    setTotalBrand(brandLength)
                }
            })
    }

    const handleCheckExcel = async () => {
        setLoadingImpt(true)
        const formData = new FormData();
        formData.append('excelFile', excelFile)

        await axios({
            method: "POST",
            url: `${host}/product/index.php?action=itemExcelData`,
            headers: {
                "Content-Type": "multipart/form-data",
            },
            data: formData
        }).then(res => {
            let data = null
            if (res.data.status === 200) {
                data = res.data.data
                setExcelData(data)
                setModalCheck(true)
                if (data.length > 0) {
                    const keys = Object.keys(data[0]); const cols = keys.map(key => ({
                        Header: key.charAt(0).toUpperCase() + key.slice(1),
                        accessor: key
                    }));
                    setColumnCheck(cols);
                    setLoadingImpt(false)
                }
            } else {
                Toaster.fire({
                    icon:"error",
                    text:data.msg,
                    position:'top-right'
                })
            }
        })

    };

    const handleImport = async () => {
        const formData = new FormData();
        formData.append('excelFile', excelFile)
        formData.append('action', 'importProduct')

        await axios({
            method: "POST",
            url: `${host}/product/index.php?action=importProduct`,
            headers: {
                "Content-Type": "multipart/form-data",
            },
            data: formData
        }).then(res => {
            if (res.data.status === 200) {
                Toaster.fire({
                    icon: 'success',
                    text: 'Import Success',
                    position: 'top-right'
                })
                setModalCheck(false)
                getData()
            }
            setExcelFile(null)
        }).catch(error => {
            Toaster.fire({
                icon: 'error',
                text: error,
                position: 'top-right'
            })
            setLoadingImpt(false)
        });

    }

    const handleFile = (event) => {
        const file = event.target.files[0];
        setExcelFile(file);
    }

    const columns = useMemo(() => [
        {
            Header: "Vendor",
            accessor: "vendor_name",
            Cell: ({ cell }) => (
                <div style={{ width: '120px' }}>
                    <Popup content={cell.value} size="tiny" trigger={
                        <div className="text-truncate">{cell.value}</div>
                    } />
                </div>
            )
        },
        {
            Header: "Article",
            accessor: "article"
        },
        {
            Header: "Barcode",
            accessor: "barcode",
            Cell: ({ cell }) => (
                <div>
                    <Popup content='Edit this barcode' size="tiny" trigger={
                        <span><Icon name="edit" color="blue" style={{ cursor: 'pointer' }} onClick={() => handleClickEditBarcode(cell.row.original)} /></span>
                    } />
                    <span>{cell.value}</span>
                </div>
            )
        },
        {
            Header: "Size",
            accessor: "size",
            Cell: ({ cell }) => {
                if (userProfile.profile.id_role === "R24060004") {
                    return (
                        <div>
                            <Popup content='Edit this Size' size="tiny" trigger={
                                <span><Icon name="edit" color="blue" style={{ cursor: 'pointer' }} onClick={() => handleClickEditSize(cell.row.original)} /></span>
                            } />
                            <span>{cell.value}</span>
                        </div>
                    )
                } else {
                    return cell.value
                }
            }
        },
        {
            Header: "Brand",
            accessor: "brand"
        },
        {
            Header: "Desc",
            accessor: "description",
            Cell: ({ cell }) => (
                <div style={{ width: '180px' }}>
                    <Popup content={cell.value} size="tiny" trigger={
                        <div className="text-truncate">{cell.value}</div>
                    } />
                </div>
            )
        },
        {
            Header: "Cogs",
            accessor: "cogs",
            Cell: ({ cell }) => (
                IDR.format(cell.value)
            )
        },
        {
            Header: "Price",
            accessor: "price",
            Cell: ({ cell }) => (
                IDR.format(cell.value)
            )
        },
        {
            Header: "Season",
            accessor: "season",
            Cell: ({ cell }) => (
                <div>
                    <Popup content='Edit this seasson' size="tiny" trigger={
                        <span><Icon name="edit" color="blue" style={{ cursor: 'pointer' }} onClick={() => handleClickEditSeasson(cell.row.original)} /></span>
                    } />
                    <span>{cell.value}</span>
                </div>
            )
        },
        {
            Header: "Brand Group",
            accessor: "brand_group"
        },
        {
            Header: "Model",
            accessor: "model"
        },
        {
            Header: "Trend",
            accessor: "trend"
        },
    ])

    const handleClickEditBarcode = (data) => {
        setSelectedRow(data)
        setModalEditBrcd(true)
    }

    const handleClickEditSeasson = (data) => {
        setSelectedRow(data)
        setModalEditSeason(true)
    }

    const handleClickEditSize = (data) => {
        setSelectedRow(data)
        setModalEditSize(true)
    }

    const handleSubmitEditBarcode = async () => {
        await axios({
            method: 'POST',
            url: `${host}/product/index.php?action=editBarcode`,
            headers: {
                "Content-Type": "application/json",
                // "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107",
            },
            data: {
                article: selectedRow.article,
                size: selectedRow.size,
                barcode: selectedRow.barcode,
            }
        }).then(res => {
            if (res.data.status === 200) {
                Toaster.fire({
                    icon: 'success',
                    text: 'Success Edit Barcode',
                    position: 'top-right'
                })
                setModalEditBrcd(false)
                getData()
            } else {
                Toaster.fire({
                    icon: 'error',
                    text: res.data.msg,
                    position: 'top-right'
                })
            }
        })
    }

    const handleSubmitEditSize = async () => {
        await axios({
            method: 'POST',
            url: `${host}/product/index.php?action=editSize`,
            headers: {
                "Content-Type": "application/json",
                // "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107",
            },
            data: {
                article: selectedRow.article,
                size: selectedRow.size,
                barcode: selectedRow.barcode,
            }
        }).then(res => {
            if (res.data.state === 200) {
                Toaster.fire({
                    icon: 'success',
                    text: 'Success Edit Barcode',
                    position: 'top-right'
                })
            } else {
                Toaster.fire({
                    icon: 'error',
                    text: res.data.msg,
                    position: 'top-right'
                })
            }
        })
    }

    const handleSubmitEditSeason = async () => {
        await axios({
            method: 'POST',
            url: `${host}/product/index.php?action=editSeason`,
            headers: {
                "Content-Type": "application/json",
                // "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107",
            },
            data: {
                article: selectedRow.article,
                season: selectedRow.season,
            }
        }).then(res => {
            if (res.data.status === 200) {
                Toaster.fire({
                    icon: 'success',
                    text: 'Success edit seasson',
                    position: 'top-right'
                })
                setModalEditSeason(false)
                getData()
            } else {
                Toaster.fire({
                    icon: 'error',
                    text: res.data.msg,
                    position: 'top-right'
                })
            }
        })
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <>
            <Container>
                <div className="mb-3 mt-2">
                    <div className="h4">Master Article</div>
                </div>
                <Row className="d-flex justify-content-center">
                    <Col xl={12} lg={12} md={12} sm={12}>
                        <Form onSubmit={handleCheckExcel}>
                            <Form.Group inline>
                                <Form.Input type="file" onChange={handleFile} label="Excel File" />
                                <Button type="submit" content="Check & Import" icon="share square outline" color="blue" />
                                <span className="ms-3 me-1">Download template</span><span><a href={urlTemplate}>click here</a></span>
                            </Form.Group>
                        </Form>
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={12}>
                        <Reacttable data={dataArticle} columns={columns} />
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={12}>
                        <Popup content={'Total ' + totalItem + ' Item`s'} trigger={
                            <Label content={totalItem + " Item's"} icon="box" color="blue" />
                        } />
                        <Popup content={'Total ' + totalArt + ' Article`s'} trigger={
                            <Label content={totalArt + ' Article'} icon="sticky note" color="blue" />
                        } />
                        <Popup content={'Total ' + totalBrand + ' Brand`s'} trigger={
                            <Label content={totalBrand + ' Brand'} icon="registered" color="blue" />
                        } />
                    </Col>
                </Row>
            </Container>

            <Modal open={modalEditBrcd} onClose={() => setModalEditBrcd(false)} backdrop='static' overflow={false} size='xs'>
                <Modal.Header>
                    <Modal.Title>Edit Barcode</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="frmEditBarcode" onSubmit={handleSubmitEditBarcode}>
                        <FormInput type="text" value={selectedRow?.barcode || ""} onChange={(e) => setSelectedRow(prev => ({ ...prev, barcode: e.target.value }))} label="Barcode to edit" />
                        <p className="fw-bold"><Icon name="exclamation circle" color="orange" /> Warning</p>
                        <p>Can only be changed before a PO is created, if a PO has already been created then this barcode on the PO Details must be changed too</p>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button content="Update Barcode" type="submit" form="frmEditBarcode" color="blue" icon="save" />
                </Modal.Footer>
            </Modal>

            <Modal open={modalEditSeason} onClose={() => setModalEditSeason(false)} backdrop="static" overflow={false} size="xs">
                <Modal.Header>
                    <Modal.Title>Edit Seasson</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="frmEditSea" onSubmit={handleSubmitEditSeason}>
                        <FormInput type="text" value={selectedRow?.season} onChange={(e) => setSelectedRow(prev => ({ ...prev, season: e.target.value }))} />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button content="Update Seasson" type="submit" form="frmEditSea" color="blue" icon="save" />
                </Modal.Footer>
            </Modal>

            <Modal open={modalEditSize} onClose={() => setModalEditSize(false)} backdrop="static" overflow={false} size="xs">
                <Modal.Header>
                    <Modal.Title>Edit Size</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="frmEditSize" onSubmit={handleSubmitEditSeason}>
                        <FormInput type="text" value={selectedRow?.size} onChange={(e) => setSelectedRow(prev => ({ ...prev, size: e.target.value }))} />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button content="Update Size" type="submit" form="frmEditSize" color="blue" icon="save" />
                </Modal.Footer>
            </Modal>

            <Modal open={modalCheck} onClose={() => setModalCheck(false)} backdrop='static' overflow={false} size='lg'>
                <Modal.Header>
                    <Modal.Title>Please double check this excel data.</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Reacttable data={excelData} columns={columnsCheck} />
                </Modal.Body>
                <Modal.Footer>
                    <Button content="I'am not sure, Cancel" color="grey" icon="ban" onClick={() => setModalCheck(false)}/>
                    <Button content="Data Oke, Import Now" color="blue" icon="save" onClick={() => handleImport()} disabled={loadingImpt} loading={loadingImpt}/>
                </Modal.Footer>
            </Modal>
        </>
    )
}